import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { useStaticQuery, graphql } from 'gatsby'

// UBO
import Plaatjie from '@ubo/plaatjie'

// Images
import LinkedIn from 'img/linkedin.inline.svg'

const StyledTeam = styled.section``

const Image = styled(Plaatjie)`
  border-radius: 50%;
  min-width: 165px;
  min-height: 165px;
  max-width: 165px;
  max-height: 165px;

  & img {
    border-radius: 50%;
  }

  @media screen and (max-width: 575px) {
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
    max-height: 100px;
  }
`

const TeamMember = styled.div`
  & span {
    color: ${(props) => props.theme.color.secondary};
    text-align: center;
  }

  @media screen and (max-width: 575px) {
    & span {
      font-size: 14px;
      line-height: 16px;
    }
  }
`

const LinkedInLink = styled.a`
  position: absolute;
  top: 140px;
  right: 80px;
  z-index: 20;
  background: white;
  border-radius: 50%;

  @media (max-width: 1199px) {
    top: 130px;
    right: 50px;
  }
  @media (max-width: 575px) {
    top: 80px;
    right: 90px;
  }
  @media (max-width: 540px) {
    top: 80px;
    right: 50px;
  }

  & svg {
    margin-bottom: 2px;
    width: 24px;
    height: 24px;
  }
`

interface TeamProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Team
}

const Team: React.FC<TeamProps> = ({ fields }) => {
  const {
    allWpTeamMember: { nodes: teamMembers },
  } = useStaticQuery<GatsbyTypes.fetchTeamMembersQuery>(graphql`
    query fetchTeamMembers {
      allWpTeamMember {
        nodes {
          id
          teammemberdetail {
            info {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 300)
                  }
                }
              }
              name
              position
              linkedin
            }
          }
        }
      }
    }
  `)

  return (
    <StyledTeam className="mb-5">
      <div className="container py-lg-5 py-3">
        <div className="row justify-content-center mb-5">
          <div className="col-sm-8 ">
            <div className="px-sm-0 px-3">
              <ParseContent content={fields?.description} />
            </div>
          </div>
        </div>
        <div className="row">
          {teamMembers
            // @ts-ignore
            .sort((a: any, b: any) =>
              a.teammemberdetail.info.name.localeCompare(
                b.teammemberdetail.info.name
              )
            )
            .map((teamMember: any, index: number) => {
              const { info } = teamMember.teammemberdetail

              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className="col-6 col-md-4 col-lg-3 mb-lg-5 mb-3 pb-4"
                >
                  <TeamMember className="d-flex flex-column align-items-center">
                    <Image image={info?.image} className="mb-3" alt="Teamlid" />
                    <span>
                      <strong>{info?.name}</strong>
                    </span>
                    <span className="px-3 text-center">{info?.position}</span>
                    <LinkedInLink
                      href={info?.linkedin}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkedIn />
                    </LinkedInLink>
                  </TeamMember>
                </div>
              )
            })}
        </div>
      </div>
    </StyledTeam>
  )
}

export default Team
